
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinForm from "@/mixins/mixinForm";
  import FPartsSelect from "@/components/f/parts/Select.vue";

  @Component({
    components: {
      FPartsSelect,
    },
  })
  export default class Normal extends Mixins(mixinForm) {
    @Prop() value: number | string;
    @Prop() options: { [id: number | string]: number | string };
    @Prop() disp_options: { [id: number | string]: number | string };
    @Prop() order?: number[] | string;
    @Prop({ default: false }) is_null: boolean;
    @Prop() null_str: string;
    @Prop({ default: false }) reset: boolean;
    @Prop({ default: "100%" }) width: string;
    @Prop() beforeadd: any;
    @Prop() afteradd: any;

    get tmp_value() {
      return this.value;
    }
    set tmp_value(val) {
      this.$emit("input", val);
    }

    element(e) {
      this.$emit("element", e);
    }

    get use_options() {
      if (Array.isArray(this.options)) {
        return this.options;
      }
      let options = [];
      if (this.order) {
        if (Array.isArray(this.order)) {
          this.order.forEach((key) => {
            options.push({ key, value: this.options[key] });
          });
        } else if (this.order == "asc") {
          var keys = Object.keys(this.options);
          keys.sort();
          keys.forEach((key) => {
            options.push({ key, value: this.options[key] });
          });
        } else if (this.order == "desc") {
          var keys = Object.keys(this.options);
          keys.sort((a, b) => {
            return a < b ? 1 : -1;
          });
          keys.forEach((key) => {
            options.push({ key, value: this.options[key] });
          });
        }
      } else {
        Object.keys(this.options).forEach((key) => {
          options.push({ key, value: this.options[key] });
        });
      }
      options = (this.beforeadd ?? []).concat(options);
      options = options.concat(this.afteradd ?? []);
      return options;
    }

    get style() {
      return {
        "--width": this.width,
      };
    }

    get use_disp_options() {
      const beforeadd_hash = {};
      const afteradd_hash = {};
      (this.beforeadd ?? []).forEach((val) => {
        beforeadd_hash[val.key] = val.value;
      });
      (this.afteradd ?? []).forEach((val) => {
        afteradd_hash[val.key] = val.value;
      });
      if (this.disp_options) {
        return {
          ...beforeadd_hash,
          ...this.disp_options,
          ...afteradd_hash,
        };
      } else {
        return {
          ...beforeadd_hash,
          ...this.options,
          ...afteradd_hash,
        };
      }
    }
  }
