import { Component, Mixins, Prop } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import master_realtime from "@/vuex/master/realtime";
import * as vali from "@/validations/validationUtil";
import { Inject, Provide } from '@/library/provide';

@Component
export default class mixinPart extends Mixins(utilMixins) { 
  @Prop() title?: string;
  @Prop() popup_title?: string;
  @Prop() popup_content?: string;
  @Prop() popup_img?: string;
  @Prop() info?: string;
  @Prop() need?: boolean;
  @Prop() object: any;//表示と編集は同じもの(objectのもの)
  @Prop() old_object?: any;

  _title: any;
  _popup_title?: any;
  _popup_content?: any;
  _popup_img?: any;
  _info?: any;
  _need?: boolean = false;

  @Inject({default: false})
  is_edit: boolean;
  @Inject({default: false})
  is_add: boolean;

  get condition() {
    return true;//override
  }

  get is_disp() {
    return this.condition;//override
  }

  @Provide('title')
  get use_title() {
    return this.title !== undefined ? this.title : this.$t(this._title);
  }

  @Provide('popup')
  get use_popup() {
    if (this.popup_title !== undefined && this.popup_content !== undefined) {
      return {title: this.popup_title, msg: this.popup_content, img: this.popup_img};
    } else if (this._popup_title !== undefined && this._popup_content !== undefined) {
      return {title: this.$t(this._popup_title), msg: this.$t(this._popup_content), img: this._popup_img};
    } else {
      return undefined;
    }
  }
  @Provide('info')
  get use_info() {
    return this.info !== undefined ? this.info : this.$t(this._info);
  }
  @Provide('need')
  get use_need() {
    return this.need !== undefined ? this.need : this._need;
  }

  get is_change() {
    return false;
  }

  @Provide('is_change')
  get use_is_change() {
    return this.old_object && this.is_change;//override
  }

  state: any = {
    reading_zip: false,
    banks: [],
    branches: [],
    banks1: [],
    branches1: [],
    banks2: [],
    branches2: [],
    taxoffices: [],
  };

  public async inputZip({zip}) {
    this.$emit('reading_zip', true);
    const address = await this.readZip(zip);
    if (address !== null) this.object.address = address;
    this.$emit('reading_zip', false);
  }
  public async inputBankName({bank_name, prefix = ''}: {bank_name?: string, prefix?: string}) {
    this.object['bank'+prefix+'_code'] = null;
    this.object['branch'+prefix+'_name'] = null;
    this.object['branch'+prefix+'_code'] = null;
    this.object['bank'+prefix+'_id'] = null;
    this.object['branch'+prefix+'_id'] = null;
    const banks = await this.readBank(bank_name);
    this.state['banks'+prefix] = banks;
  }
  public clickBank({bank, prefix = ''}: {bank: any, prefix?: string}) {
    this.object['bank'+prefix+'_name'] = bank.name;
    this.object['bank'+prefix+'_code'] = bank.bank_code;
    this.object['bank'+prefix+'_id'] = bank.id;
    this.state['banks'+prefix] = [];
  }
   public async inputBranchName({branch_name, prefix = ''}: {branch_name?: string, prefix?: string}) {
    this.object['branch'+prefix+'_code'] = null;
    this.object['branch'+prefix+'_id'] = null;
    const branches = await this.readBranch(this.object['bank'+prefix+'_code'], branch_name);
    this.state['branches'+prefix] = branches;
  }
  public clickBranch({branch, prefix = ''}: {branch: any, prefix?: string}) {
    this.object['branch'+prefix+'_name'] = branch.name;
    this.object['branch'+prefix+'_code'] = branch.branch_code;
    this.object['branch'+prefix+'_id'] = branch.id;
    this.state['branches'+prefix] = [];
  }

  private async readZip(zip: null | string) {
    if (zip !== null) {
      if (!vali.is_zip(zip)) return null;
      return await master_realtime.readZip({zip});
    } else {
      return null;
    }
  }
  private async readBank(bank_name: null | string) {
    if (bank_name !== null)
      return master_realtime.readBank({bank_name});
    else
      return [];
  }
  private async readBranch(bank_code: null | string, branch_name: null | string) {
    if (bank_code !== null && branch_name !== null) {
      if (Number(bank_code) == 9900) {
        if (String(branch_name).length >= 3) {
          
          var branch_type = Number(String(branch_name).slice(0, 1));
          var branch_code = String(branch_name).slice(1, 3);
          
          //もし先頭が0なら振替, 2,3桁めに9をつける
          //そうでないなら総合や普通, 2,3桁目に8をつける
          if (branch_type == 0) {
            //振替口座
            branch_code = branch_code + "9";
          } else {
            //総合・普通口座
            branch_code = branch_code + "8";
          }
          
          return master_realtime.readBranchYucho({bank_code, branch_code});
        } else {
          return [];
        }
      } else {
        return master_realtime.readBranch({bank_code, branch_name});
      }
    } else {
      return [];
    }
  }


  private async readTaxoffice(taxoffice_name: null | string) {
    if (taxoffice_name !== null)
      return await master_realtime.readTaxoffice({taxoffice_name});
    else
      return []
  }
  public async inputTaxofficeName({taxoffice_name}: {taxoffice_name?: string}) {
    this.object['taxoffice_id'] = null;
    this.object['taxoffice_num'] = null;
    const taxoffices = await this.readTaxoffice(taxoffice_name);
    this.state.taxoffices = taxoffices;
  }
  public clickTaxoffice({taxoffice}) {
    this.object['taxoffice_id'] = taxoffice.taxoffice_id;
    this.object['taxoffice_num'] = taxoffice.taxoffice_num;
    this.object['taxoffice_name'] = taxoffice.name;
    this.state.taxoffices = [];
  }
}