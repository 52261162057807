
import { Inject } from '@/library/provide';
import info_dialog from '@/vuex/info_dialog';
import { Component, Prop, Vue } from 'vue-property-decorator';
import LProgress from "@/components/l/Progress.vue";

@Component({
  components: {
    LProgress,
  }
})
export default class Tab extends Vue {
  @Prop({default: false}) loading: boolean;
  @Prop() is_edit_custom: boolean;
  @Prop() is_add_custom: boolean;
  @Prop() link?: string;

  get is_input() {
    return this.use_is_edit || this.use_is_add;
  }
  get use_is_edit() {
    if (this.is_edit_custom === undefined) {
      return this.is_edit;
    } else {
      return this.is_edit_custom;
    }
  }
  get use_is_add() {
    if (this.is_add_custom === undefined) {
      return this.is_add;
    } else {
      return this.is_add_custom;
    }
  }

  detail() {
    if (this.popup !== null) {
      info_dialog.openDialog(this.popup);
    } else if (this.link !== null) {
      window.open(this.link, '_blank');
    }
  }

  @Inject({default: false})
  is_edit: boolean;
  @Inject({default: false})
  is_add: boolean;
  @Inject({default: null})
  title?: string;
  @Inject({default: null})
  popup?: string;
  @Inject({default: null})
  info?: string;
  @Inject({default: false})
  need: boolean;
  @Inject({default: false})
  is_change: boolean;

  @Inject({default: false})
  force_sp: boolean;
}
