
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinForm from "@/mixins/mixinForm";
  import FPartsText from "@/components/f/parts/Text.vue";

  @Component({
    components: {
      FPartsText,
    },
  })
  export default class Text extends Mixins(mixinForm) {
    @Prop() value: any;
    @Prop({ default: "text" }) type: string;
    @Prop({ default: "" }) placeholder: string;
    @Prop({ default: false }) disabled: boolean;
    @Prop({ default: "left" }) text_align: string;
    @Prop() width: string;
    @Prop() filter: undefined | ((val: string) => string);

    @Prop() num: number;
    @Prop() sum: number;
    @Prop() join: string;

    get disp_value() {
      if (this.type == "password") {
        return "●●●●●●●●";
      } else if (this.filter === undefined) {
        return this.value;
      } else {
        return this.filter(this.value);
      }
    }

    get tmp_value() {
      if (this.num !== undefined) {
        if (this.value !== null) {
          const name = String(this.value).split(this.join);
          if (name[this.num] !== null && name[this.num] !== undefined) {
            return name[this.num];
          } else {
            return "";
          }
        } else {
          return "";
        }
      } else {
        return this.value;
      }
    }
    set tmp_value(val) {
      if (this.num !== undefined) {
        let name = [];
        if (this.value !== null) {
          name = String(this.value).split(this.join);
        }

        if (val !== undefined && val !== null) name[this.num] = String(val);
        else name[this.num] = "";
        if (
          name.reduce((carry, val) => {
            return carry + val.length;
          }, 0) == 0
        ) {
          this.$emit("input", null);
        } else {
          let str = "";
          for (let i = 0; i < this.sum; i++) {
            if (name[i] !== null && name[i] !== undefined && name[i] != "") {
              str += name[i];
            }
            if (i != this.sum - 1) str += this.join;
          }
          this.$emit("input", str);
        }
      } else {
        this.$emit("input", val);
      }
    }

    get style() {
      return {
        "--width": this.width,
      };
    }
    element(e) {
      this.$emit("element", e);
    }
  }
