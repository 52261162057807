
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinForm from '@/mixins/mixinForm';
import FPartsRadio from "@/components/f/parts/Radio.vue";

@Component({
  components: {
    FPartsRadio,
  }
})
export default class Normal extends Mixins(mixinForm) {
  @Prop() value: number | string;
  @Prop() options: {[id: number | string]: number | string};
  @Prop() disp_options: {[id: number | string]: number | string};
  @Prop() order?: number[];
  @Prop({default: false}) is_vertical: boolean;
  @Prop({default: false}) is_option_vertical: boolean;
  @Prop() filter: undefined | ((val: string) => string);

  get tmp_value() {
    return this.value;
  }
  set tmp_value(val) {
    this.$emit('input', val);
  }
  get disp_value() {
    if (this.filter === undefined) {
      return this.use_disp_options[this.value];
    } else {
      return this.filter(this.use_disp_options[this.value]);
    }
  }
  get use_options() {
    var options = [];
    if (this.order) {
      this.order.forEach(key => {
        options.push({key: key, value: this.options[key]});
      });
    } else {
      Object.keys(this.options).forEach(key => {
        options.push({key: key, value: this.options[key]});
      });
    }
    return options;
  }
  get use_disp_options() {
    if (this.disp_options) {
      return this.disp_options;
    } else {
      return this.options;
    }
  }

}
