
import router from '@/router';
import { Component, Vue, Prop } from 'vue-property-decorator';


@Component({
  components: {
  }
})
export default class Config extends Vue {
  @Prop() value: string;
  @Prop() contents: (string | {name: string, route?: string, page?: string})[];

  get sub_contents() {
    return this.contents.slice(0, this.contents.length - 1);
  }
  get active_content() {
    return this.contents[this.contents.length - 1];
  }
  clickRoute(route: string) {
    this.$emit('input', route);
  }
  clickPage(page: string) {
    router.push({name: page, query: this.$route.query});
  }
}
