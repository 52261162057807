
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Radio extends Vue {
  @Prop() value: any;
  @Prop() name: string;
  @Prop() this_value: number;

  get tmp_value() {
    return this.value;
  }
  set tmp_value(val) {
    this.$emit('input', val);
  }

  get use_this_value() {
    return Number(this.this_value);
  }
}
