
  import { Component, Vue, Prop } from "vue-property-decorator";

  @Component({
    components: {},
  })
  export default class Select extends Vue {
    @Prop() value: any;
    @Prop() options: { [key: number | string]: number | string }[];
    @Prop() is_null: boolean;
    @Prop() null_str: string;
    @Prop() reset: boolean;

    get selected_value() {
      return this.value == null ? "null" : this.value;
    }
    set selected_value(val) {
      this.$emit("input", val == "null" ? null : val);
    }

    change(e) {
      if (this.reset) {
        e.target.value = "null";
      }
      this.$emit("element", e);
    }
  }
