
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Text extends Vue {
  @Prop() value: any;
  @Prop() type: string;
  @Prop() placeholder: string;
  @Prop() disabled: boolean;
  @Prop() text_align: string;

  get tmp_value() {
    return this.value;
  }
  set tmp_value(val) {
    this.$emit('input', val == '' ? null : val); 
  }

  element(e) {
    this.$emit('element', e);
  }

  k_delete(e) {
    if (e.target.value.length == 0 || (e.target.selectionStart == 0 && e.target.selectionEnd == 0)) {
      e.preventDefault();
      this.prev_focus(e);
    }
  }

  prev_focus(e) {
    const index = $('.text_focus').index(e.target);
    const prev_element = <HTMLInputElement>$('.text_focus')[index - 1];
    if (prev_element) {
      if (prev_element.type == 'text' || prev_element.type == 'url' || prev_element.type == 'tel') {
        prev_element.selectionStart = 0;
        prev_element.selectionEnd = prev_element.value.length;
        prev_element.focus();
      }
    }
  }
  next_focus(e) {
    const index = $('.text_focus').index(e.target);
    const next_element = <HTMLInputElement>$('.text_focus')[index + 1];
    console.log(index, next_element);
    if (next_element) {
      if (next_element.type == 'text' || next_element.type == 'url' || next_element.type == 'tel') {
        next_element.selectionStart = 0;
        next_element.selectionEnd = next_element.value.length;
        next_element.focus();
      }
    }
  }
}
